import {
  GenerateContractSigningLinkReceivablesContractSignaturePostParams,
  CheckContractSignatureReceivablesContractSignaturePatchParams,
} from '@repo/common/dtos';
import axios from 'axios';

export type ContractSignatureResponse = {
  link: string;
  documentId: string;
};

export type CheckContractSignatureResponse = {
  documentStatus: string;
};

class Contract {
  baseUrl: string;
  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/contract`;
  }

  async generateSigningLink(params: GenerateContractSigningLinkReceivablesContractSignaturePostParams): Promise<ContractSignatureResponse> {
    const response = await axios.post<ContractSignatureResponse>(`${this.baseUrl}/signature`, undefined, {
      params,
    });
    return response.data;
  }

  async checkContractSignature(params: CheckContractSignatureReceivablesContractSignaturePatchParams): Promise<CheckContractSignatureResponse> {
    const response = await axios.patch<CheckContractSignatureResponse>(`${this.baseUrl}/signature`, undefined, {
      params,
    });
    return response.data;
  }
}

export default Contract;
