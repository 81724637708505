import axios from 'axios';
import { ApplicationFinancialInformationInput, ApplicationFinancialInformationOutput, LoanPaymentIn } from '../dtos';

class Operation {
  baseUrl: string;

  constructor(parentUrl: string) {
    this.baseUrl = `${parentUrl}/operation`;
  }

  async saveApplicationFinancialInformation(applicationId: string, financial: ApplicationFinancialInformationInput): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}`, financial);
    return response.data;
  }

  async calculateLoanConditions(adminEmail: string, partialFinancial: ApplicationFinancialInformationInput): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/loan/calculate`, partialFinancial, {
      params: {
        admin_email: adminEmail,
      },
    });
    return response.data;
  }

  async registerPayment(applicationId: string, payment: LoanPaymentIn): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.post<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}/payment`, payment);
    return response.data;
  }

  async deletePayment(applicationId: string, paymentId: string): Promise<ApplicationFinancialInformationOutput> {
    const response = await axios.delete<ApplicationFinancialInformationOutput>(`${this.baseUrl}/application/${applicationId}/payment`, {
      params: {
        payment_id: paymentId,
      },
    });
    return response.data;
  }
}

export default Operation;
